import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Column } from 'react-table'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Badge, Button, FormField, Icon, Spinner } from '@myeh/design-system'

import useUserPermissions from '../../../hooks/useUserPermissions'
import Right from '../../../models/Right'
import { Toggle, ToggleModification } from '../../../models/Toggle'
import { featureToggle } from '../../../services/FeatureToggle'
import { exportToggles, getToggles, postToggle } from '../../../services/MyEHToggle'
import { getEnvironment, getSubEnvironments } from '../../../utils/env'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import Datatable from '../../molecules/Datatable/Datatable'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'

import style from './FeatureTogglesPage.module.scss'

export function FeatureTogglesPage(): ReactElement {
  const { oidcUser } = useReactOidc()

  const [loading, setLoading] = useState<boolean>(true)
  const [toggles, setToggles] = useState<Array<Toggle>>([])
  const [displayedToggles, setDisplayedToggles] = useState<Array<Toggle>>([])
  const [toggleNameSearchInput, setToggleNameSearchInput] = useState<string>()
  const [subEnv, setSubEnv] = useState<string>(getSubEnvironments()?.[0])
  const [statusFilter, setStatusFilter] = useState<string>('all')
  const exportTogglesButton = 'Export'

  const { hasPermission } = useUserPermissions()
  const canWrite = hasPermission(Right.TOGGLES_WRITE)

  const statuses = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ]

  const possibleSubEnvs = getSubEnvironments().map((subEnv) => ({ value: subEnv, label: subEnv }))

  useEffect(() => {
    let displayedToggles = toggles
    if (statusFilter !== 'all') {
      const isActive = statusFilter === 'active'
      displayedToggles = toggles.filter((e) => e.value === isActive)
    }
    if (toggleNameSearchInput && toggleNameSearchInput !== '') {
      displayedToggles = displayedToggles.filter((e) => e.name.includes(toggleNameSearchInput as string))
    }
    setDisplayedToggles(displayedToggles as Array<Toggle>)
  }, [toggles, statusFilter, toggleNameSearchInput])

  const onChangeSubEnv = (newSubEnv: string) => {
    setSubEnv(newSubEnv)
    fetchToggles()
  }

  const fetchToggles = useCallback(async () => {
    try {
      setLoading(true)
      const toggles = await getToggles(oidcUser, subEnv)
      setToggles(toggles)
    } finally {
      setLoading(false)
    }
  }, [oidcUser, subEnv])

  useEffect(() => {
    fetchToggles()
  }, [oidcUser, fetchToggles])

  const columns: Array<Column<Toggle>> = [
    {
      Header: 'Toggle name',
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true
    },
    {
      Header: () => <span className='flex flex-column items-center'>Status{subEnv ? ` (${subEnv})` : ''}</span>,
      accessor: 'value',
      Cell: ({ value }) => (
        <span className='flex flex-column items-center'>
          {value && <Badge type='positive'>Active</Badge>}
          {!value && <Badge type='default'>Inactive</Badge>}
        </span>
      ),
      disableSortBy: true
    }
  ]
  if (featureToggle('tmp-feature-toggles-edit')) {
    columns.push({
      Header: () => <span className='flex flex-column items-center'>Modify{subEnv ? ` (${subEnv})` : ''}</span>,
      accessor: (row) => row,
      id: 'toggleEdit',
      Cell: (toggle: { value: Toggle }) => {
        const { value, name, description } = toggle.value
        const [openedModal, setOpenedModal] = useState('')
        const { mutate, isLoading: isUpdateLoading } = useMutation(
          (body: ToggleModification) => postToggle(oidcUser, body),
          {
            onSuccess: () => {
              fetchToggles()
              setOpenedModal('success')
            },
            onError: () => {
              setOpenedModal('error')
            }
          }
        )
        const closeModal = () => setOpenedModal('')
        return (
          <>
            <span className='flex flex-column items-center'>
              <Button
                variant='primary'
                size='small'
                data-testid='myeh-link'
                disabled={!canWrite}
                onClick={() => setOpenedModal('validation')}
              >
                {value ? 'Deactivate' : 'Activate'}
              </Button>
            </span>
            <SimpleModal
              open={openedModal === 'validation'}
              onClose={closeModal}
              title={`Are you sure you want to ${value ? 'deactivate' : 'activate'} ${name} in ${getEnvironment()}${
                subEnv ? ` (${subEnv})` : ''
              }?`}
              {...(description && { body: `Description of the toggle is "${description}".` })}
              footer={
                <>
                  <Button
                    size='small-medium'
                    variant='secondary'
                    className='mr3'
                    onClick={() => setOpenedModal('')}
                    disabled={isUpdateLoading}
                    data-testid='dismiss-button-modify-toggle'
                  >
                    No, dismiss
                  </Button>
                  <Button
                    size='small-medium'
                    variant='primary'
                    onClick={() =>
                      mutate({ name, value: !value, env: subEnv || process.env.REACT_APP_ENVIRONMENT || '' })
                    }
                    disabled={isUpdateLoading}
                    data-testid='continue-button-modify-toggle'
                  >
                    {isUpdateLoading ? <Spinner aria-label='spinner' size='medium' /> : 'Yes, continue'}
                  </Button>
                </>
              }
              showCloseIcon={!isUpdateLoading}
              hideOnClickOutside={!isUpdateLoading}
              testId='validation-modal'
            />
            <SimpleModal
              open={openedModal === 'success'}
              onClose={closeModal}
              titleIcon='success'
              title={`Toggle was successfully ${value ? 'deactivated' : 'activated'}.`}
              footer={
                <Button
                  size='small-medium'
                  variant='primary'
                  onClick={closeModal}
                  data-testid='ok-button-success-modal'
                >
                  OK
                </Button>
              }
              testId='success-modal'
              showCloseIcon={false}
              hideOnClickOutside={false}
            />
            <SimpleModal
              open={openedModal === 'error'}
              onClose={closeModal}
              titleIcon='error'
              title='Toggle modification process failed'
              body='We are doing our best so you can modify the toggle value. Please try again later.'
              footer={
                <Button size='small-medium' variant='primary' onClick={closeModal} data-testid='ok-button-error-modal'>
                  OK
                </Button>
              }
              testId='error-modal'
            />
          </>
        )
      },
      disableSortBy: true
    })
  }

  return (
    <div>
      <PageTitle title='Feature toggles' />
      <div className='pa4'>
        <div className='pb4'>
          On this page you will find the list of existing toggles for the environment you are connected to.
          <br />
          This gives more flexibility to teams for activating/deactivating features in the different environments.
        </div>
        {loading ? (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        ) : (
          <>
            <div className='flex items-center justify-between'>
              <div className='w-50 pa2'>
                <FormField.Input
                  appearance='outline'
                  name='toggleNameSearch'
                  label='Toggle Name'
                  onChange={(e) => setToggleNameSearchInput(e.currentTarget.value)}
                  data-testid='toggle-name-search'
                >
                  <FormField.Suffix>
                    <Icon name='search' size='medium' />
                  </FormField.Suffix>
                </FormField.Input>
              </div>
              <div className='w-25 pa2'>
                <FormField.Dropdown
                  appearance='outline'
                  name='statusFilter'
                  placeholder='Status'
                  label='Status'
                  floatLabel='auto'
                  defaultValue='all'
                  items={statuses}
                  onChange={(v) => setStatusFilter(v)}
                  data-testid='toggle-status-filter'
                />
              </div>
              <div className='pa2'>
                <Button
                  variant='secondary'
                  size='small-medium'
                  onClick={() => exportToggles(displayedToggles)}
                  data-testid='export-toggles-button'
                >
                  <Icon name='download' className='desy-mr-2' />
                  {exportTogglesButton}
                </Button>
              </div>
            </div>
            {featureToggle('tmp-feature-toggles-edit') && (
              <>
                <hr />
                <div className='w-25 pa2'>
                  <FormField.Dropdown
                    appearance='outline'
                    name='subEnvFilter'
                    placeholder='Sub-environment'
                    label='Sub-environment'
                    floatLabel='auto'
                    defaultValue={subEnv}
                    items={possibleSubEnvs}
                    onChange={onChangeSubEnv}
                    value={subEnv}
                    data-testid='toggle-subEnv-filter'
                  />
                </div>
              </>
            )}
            <Datatable<Toggle> data={displayedToggles} columns={columns} isPaginable />
          </>
        )}
      </div>
    </div>
  )
}
